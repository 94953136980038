<template>
    <div class="Summit">
        <div class="Summit_containt">
            <div class="title">
                <h3>往届回顾</h3>
            </div>
            <div class="Summit_main">
                <app-swiper class="warplist" :enablePagination="true">
                    <app-swiper-item v-for="item in review" :key="item.id" class="list_item">
                        <div class="left" :style="bgimg">
                            <div class="top_left">
                                <h3>2019康马展</h3>
                                <h4>品牌国际开放日</h4>
                                <span></span>
                            </div>
                            <div class="time">
                                <h3>时间：</h3>
                                <p>{{item.time}}</p>
                            </div>
                            <div class="place">
                                <h3>地点：</h3>
                                <p>{{item.place}}</p>
                            </div>
                            <div class="Sponsorunit">
                                <h3>主办单位：</h3>
                                <p>{{item.Sponsorunit}}</p>
                            </div>
                            <div class="BrightSpot">
                                <h3>亮点：</h3>
                                <p>{{item.BrightSpot}}</p>
                            </div>
                        </div>
                        <div class="right">
                            <img :src="item.imgUrl" alt="">
                        </div>
                    </app-swiper-item>
                </app-swiper>
            </div>
        </div>
        <div class="Professional">
            <ul class="special_Warp">
                <li v-for="item in special" :key="item.id">
                    <div class="numbers">{{item.numbers}}</div>
                    <div class="texts">{{item.texts}}</div>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                review: [
                    {
                        title: '2019康马展 品牌国际开放日',
                        id: '0',
                        time: '2019-08-29  09:00~18:00',
                        place: ' 深圳会展中心',
                        Sponsorunit: '汇客集团',
                        BrightSpot: this.$t('OrganizingActivities.ApplicationContent'),
                        imgUrl: '/images/Summit/pic_wangjie_1_@2x.png',
                    },
                    {
                        title: '2019康马展 品牌国际开放日',
                        id: '1',
                        time: '2019-08-29  09:00~18:00',
                        place: ' 深圳会展中心',
                        Sponsorunit: '汇客集团',
                        BrightSpot: this.$t('OrganizingActivities.ApplicationContent'),
                        imgUrl: '/images/Summit/pic_wangjie_1_@2x.png',
                    }
                ],
                bgimg: {
                    backgroundImage: "url(/images/Summit/bg_ditu_7@2x.png)",
                    backgroundRepeat: "no-repeat",
                },
                special: [
                    {numbers: '55+', texts: '专题论坛'},
                    {numbers: '800+', texts: '专题演讲'},
                    {numbers: '26+', texts: '国家和地区'},
                    {numbers: '9+', texts: '行业领域'},
                    {numbers: '9429+', texts: '专业听众'},
                ]
            }
        },
    }
</script>

<style lang="scss" scoped>
    .Summit {
        width: 100%;
        background: #F6F6F6;

        .Summit_containt {
            width: 1320px;
            margin: auto;
            padding-top: 20px;

            .title {
                @include titles();

                padding-top: 80px;

                h3 {
                    font-size: 26px;
                    font-weight: 600;
                    color: #333333;

                }
            }

            .Summit_main {
                width: 100%;
                padding-top: 55px;

                .swiper-container {
                    width: 100%;

                    .list_item {
                        display: flex;
                        width: 100%;
                        min-height: 562px;
                        background: #FFFFFF;
                        padding-top: 33px;
                        box-sizing: border-box;

                        .left {
                            width: 35%;
                            height: 502px;
                            padding: 20px 0 0 66px;
                            box-sizing: border-box;
                            background-position-y: 305px;

                            .top_left {
                                h3 {
                                    font-family: Microsoft YaHei UI;
                                    font-weight: bold;
                                    color: rgba(102, 102, 102, 1);
                                    font-size: 26px;
                                }

                                h4 {
                                    font-family: Microsoft YaHei UI;
                                    font-weight: 500;
                                    color: rgba(102, 102, 102, 1);
                                    font-size: 17px;
                                    line-height: 40px;
                                    margin-bottom: 5px;
                                }

                                span {
                                    display: block;
                                    width: 36px;
                                    height: 6px;
                                    background: rgba(250, 210, 20, 1);
                                }
                            }

                            .time {
                                margin-top: 30px;

                                h3 {
                                    font-family: Microsoft YaHei UI;
                                    font-weight: 600;
                                    color: black;
                                    font-size: 16px;
                                    margin-bottom: 5px;
                                }

                                p {
                                    font-size: 16px;
                                    color: rgba(102, 102, 102, 1);
                                    font-family: Microsoft YaHei UI;
                                    font-weight: 400;
                                }
                            }

                            .place {
                                margin-top: 30px;

                                h3 {
                                    font-family: Microsoft YaHei UI;
                                    font-weight: 600;
                                    color: black;
                                    font-size: 16px;
                                    margin-bottom: 5px;
                                }

                                p {
                                    font-size: 16px;
                                    color: rgba(102, 102, 102, 1);
                                    font-family: Microsoft YaHei UI;
                                    font-weight: 400;

                                }
                            }

                            .Sponsorunit {
                                margin-top: 30px;

                                h3 {
                                    font-family: Microsoft YaHei UI;
                                    font-weight: 600;
                                    color: black;
                                    font-size: 16px;
                                    margin-bottom: 5px;
                                }

                                p {
                                    font-size: 16px;
                                    color: rgba(102, 102, 102, 1);
                                    font-family: Microsoft YaHei UI;
                                    font-weight: 400;
                                }
                            }

                            .BrightSpot {
                                margin-top: 30px;

                                h3 {
                                    font-family: Microsoft YaHei UI;
                                    font-weight: 600;
                                    color: black;
                                    font-size: 16px;
                                    margin-bottom: 5px;

                                }

                                p {
                                    font-size: 16px;
                                    color: rgba(102, 102, 102, 1);
                                    font-family: Microsoft YaHei UI;
                                    font-weight: 400;
                                    width: 250px;
                                }
                            }

                        }

                        .right {
                            width: 65%;
                            height: 80%;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }

            }
        }
    }

    .Professional {
        height: 139px;
        background: #FAD214;

        .special_Warp {
            width: 710px;
            margin: auto;
            display: flex;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            justify-content: space-between;

            li {
                display: flex;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                flex-direction: column;
                height: 139px;
                justify-content: space-between;
                padding: 36px 0;
                box-sizing: border-box;
                text-align: center;

                .numbers {
                    font-size: 36px;
                    font-family: Microsoft YaHei UI;
                    font-weight: 600;
                    color: rgba(51, 51, 51, 1);

                }

                .texts {
                    font-family: Microsoft YaHei UI;
                    font-weight: 400;
                    color: rgba(51, 51, 51, 1);
                    line-height: 30px;
                }
            }
        }
    }

    @media screen and (max-width: 1024px) {
        .Summit .Summit_containt {
            width: 100%;
            margin: auto;
            padding-top: 0px;
        }

        .Summit .Summit_containt .title {
            @include titles();

            padding-top: 36px;
            margin-left: 36px;
        }

        .Summit .Summit_containt .Summit_main .swiper-container .list_item .left {
            width: 30%;
            height: 502px;
            padding: 20px 0 0 37px;
            box-sizing: border-box;
            background-position-y: 305px;
        }

        .Summit .Summit_containt .Summit_main .swiper-container .list_item .right {
            width: 72%;
            height: 80%;
        }

        .Summit .Summit_containt .Summit_main .swiper-container .list_item {
            display: flex;
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            width: 100%;
            min-height: 542px;
            background: #FFFFFF;
            padding-top: 33px;
            box-sizing: border-box;
        }
    }
</style>