<template>
    <div class="PastReview">
        <!-- <Header/> -->
        <Summit/>
        <Regression/>
        <Footer/>
    </div>
</template>
<script>
import Header from '../../components/header'
import Summit from './chidren/Summit'
import Regression from './chidren/regression'
import Footer from '../home/children/Footer'
export default {
    components:{
        Header,
        Summit,
        Regression,
        Footer
    }
}
</script>

<style lang="less" scoped>

</style>