import { render, staticRenderFns } from "./regression.vue?vue&type=template&id=254bfe10&scoped=true&"
import script from "./regression.vue?vue&type=script&lang=js&"
export * from "./regression.vue?vue&type=script&lang=js&"
import style0 from "./regression.vue?vue&type=style&index=0&id=254bfe10&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "254bfe10",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/wwwroot/conmart-expo-website/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('254bfe10')) {
      api.createRecord('254bfe10', component.options)
    } else {
      api.reload('254bfe10', component.options)
    }
    module.hot.accept("./regression.vue?vue&type=template&id=254bfe10&scoped=true&", function () {
      api.rerender('254bfe10', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/pastreview/chidren/regression.vue"
export default component.exports