<template>
    <div class="regression">
        <div class="regression_warp">
            <div class="header">
                 <h2>2019康马品牌国际开放日</h2>
            </div>
            <div class="introduce">
                <p class="title_text">据赛克思液压国内销售负责人胡红女士介绍，2019康马展期间，有许多来自印度、马来西亚、孟佳拉国、泰国、台湾等国家和地区的国际买家前来咨询洽谈。</p>
                <div class="IMGurl">
                    <img src="/images/Summit/img_wangjie_2@2x.png" alt />
                </div>
                <p class="qiatan">孟家拉国的客商与赛克液压的工作人员针对代理事宜进行洽.谈</p>
               <div class="text_Warp">
                    <p>目前，赛克思已经在国内外设立50多个经销网点，销售网络覆盖全国并延伸全球，部分产品早已进入欧美市场，并得到客户的高度认可。</p>
                    <p> 胡红表示在赛克思内部早已建立独具优势的制造体系，具备从精密铸造、精密加工、热处理、双金属烧结、组装到测试全部柱塞泵、马达的整套制作工艺及装备，并拥有众多国际先进的全自动机械加工和液压元件测试设备。</p>
               </div>
               <div class="ConsultationList">
                   <ul>
                       <li>
                           <img src="/images/Summit/img_wangjie_3@2x.png" alt />
                       </li>
                         <li>
                           <img src="/images/Summit/img_wangjie_4@2x.png" alt />
                       </li>
                         <li>
                            <img src="/images/Summit/img_wangjie_5@2x.png" alt />
                       </li>
                   </ul>
               </div>
                <div class="text_Warp">
                     <p>目前，赛克思已经在国内外设立50多个经销网点，销售网络覆盖全国并延伸全球，部分产品早已进入欧美市场，并得到客户的高度认可。</p>
                    <p> 胡红表示在赛克思内部早已建立独具优势的制造体系，具备从精密铸造、精密加工、热处理、双金属烧结、组装到测试全部柱塞泵、马达的整套制作工艺及装备，并拥有众多国际先进的全自动机械加工和液压元件测试设备。</p>
               </div>
                <div class="text_Warp title_bo">
                  <p>目前，赛克思已经在国内外设立50多个经销网点，销售网络覆盖全国并延伸全球，部分产品早已进入欧美市场，并得到客户的高度认可。</p>
                    <p> 胡红表示在赛克思内部早已建立独具优势的制造体系，具备从精密铸造、精密加工、热处理、双金属烧结、组装到测试全部柱塞泵、马达的整套制作工艺及装备，并拥有众多国际先进的全自动机械加工和液压元件测试设备。</p>
               </div>
            </div>
            <div class="footer">
                <div class="left">
                    <div class="Shareto">分享至：</div>
                    <ul>
                        <li class="weixing">
                            <img src="/images/Summit/icon_weixing@2x.png" alt="">
                            <div class="QRcode">
                                <img src="/images/Summit/img_erweima@2x.png" alt="">
                            </div>
                        </li>
                        <li><img src="/images/Summit/icon_xinlang@2x.png" alt=""></li>
                        <li><img src="/images/Summit/icon_in@2x.png" alt=""></li>
                        <li><img src="/images/Summit/icon_f@2x.png" alt=""></li>
                        <li><img src="/images/Summit/icon_gezi@2x.png" alt=""></li>
                    </ul>
                    </div>
                <!-- <div class="right">
                    <router-link :to="`/${this.$route.params.lang}/SignupImmediately`" class="immediately">立即报名</router-link>
                    
                    <div class="Return" @click="Return">
                        返回
                    </div>
                </div> -->
            </div>



            
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
           
        }
    },
    methods:{
       Return(){
        //    this.$router.replace('/zh/conferenceactivities')
         this.$router.go(-1);//返回上一层
       } 
    }
}
</script>

<style lang="less" scoped>
.regression{
    width: 100%;
    background: #F6F6F6;
    .regression_warp{
        width: 65%;
        margin: auto;
        padding-top: 50px;
        box-sizing: border-box;
      
        padding-bottom: 166px;
       .header{
           width: 100%;
           border-bottom: 1px solid #D8D8D8;
           h2{
                font-family:Microsoft YaHei UI;
                font-weight:600;
                color:rgba(51,51,51,1);
                text-align: center;
                line-height: 84px;
           }
       } 
       .introduce{
           p{
               font-size: 16px;
           }
           .title_text{
               padding-top: 49px;
                padding-bottom: 41px;
           }
           .IMGurl{
               width: 100%;
               height: 100%;
               img{
                    width: 100%;
                    height: 100%;
               }
           }
           .qiatan{
               margin-top: 41px;
               margin-bottom: 52px;
           }
           .text_Warp{
               p{
                    font-family:Microsoft YaHei UI;
                    font-weight:400;
                    color:rgba(51,51,51,1);
                    font-size: 16px;
                    line-height: 28px;
               }
           }
           .ConsultationList{
               margin-top: 48px;
               margin-bottom: 40px;
               ul{
                   display: flex;
                     display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
                   width: 100%;
                   justify-content: space-between;
                   li{
                       width: 30%;
                       img{
                           width: 100%;
                       }
                   }
               }
           }
           .title_bo{
                margin-top: 28px;
           }
       }
       .footer{
           width: 100%;
           height: 34px;
           border-top:1px solid #D8D8D8; 
           margin-top: 74px;
           display: flex;
             display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
           align-items: center;
           padding-top: 40px;
           justify-content: space-between;
           .left{
                  display: flex;
                    display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
                .Shareto{
                        height: 36px;
                        font-family:Microsoft YaHei UI;
                        font-weight:400;
                        color:rgba(51,51,51,1);
                        font-size: 18px;
                        line-height: 36px;
                }
                ul{
                    display: flex;
                      display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
                    margin-left: 15px;
                    li{
                        margin-right: 18px;
                        
                    }
                }
           }
           .right{
              display: flex;
                display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: -webkit-flex;
               .immediately{
                   display: block;
                   width: 126px;
                   height: 40px;
                   line-height: 40px;
                   text-align: center;
                   color: #ffffff;
                   border-radius:10px; 
                   background: #FAD214;
                    font-family:Microsoft YaHei UI;
                    font-weight:400;
                    color:rgba(255,255,255,1);
                    margin-right: 36px;
               }
               .Return{
                   width: 108px;
                   height: 40px;
                   border: 1px solid #CCCCCC;
                   border-radius:10px; 
                   line-height: 40px;
                   text-align: center;
                    font-family:Microsoft YaHei UI;
                    font-weight:400;
                    color:rgba(153,153,153,1);
               }
           }

       }

    }
}
 .weixing{
    position: relative;
    .QRcode{
        position: absolute;
        left: -37px;
        top: 58px;
        display: none;
    }
}
.weixing:hover .QRcode{
    display: block;
}
@media screen and (max-width: 1024px) {
 .regression .regression_warp {
        width: 90%;
        margin: auto;
        padding-top: 50px;
        box-sizing: border-box;
        padding-bottom: 70px;
    }

}
</style>
